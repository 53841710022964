@import '../../../styles/styles.scss';

.Card {
  background-color: var(--color-background-card);
  padding: 2rem;
  gap: 2rem;
  border-radius: 0.5rem;

  img {
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 1px 1px 4px rgba(115, 115, 115, 0.25);
    transition: 0.5s all ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.07);
    }
  }

  .Landscape {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;

    .Thumbnail {
      max-width: 50%;
      flex-grow: 2;
    }

    .Left {
      flex-grow: 1;
      max-width: 50%;
    }

    img {
      max-width: 420px;
      height: 260px;
    }
  }
}

.Portrait {
  img {
    height: 220px;
  }
}

a.Card {
  box-sizing: border-box;
  color: var(--color-font-primary);
  text-decoration: none;

  article {
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: var(--border-radius-medium);
  }

  &:visited {
    color: var(--color-font-primary);
    text-decoration: none;
  }

  &:hover>* {
    background-color: var(--color-background-secondary);
  }
}

@media only screen and (max-width: 769px) {
  .Card {

    .Landscape {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .Left {
        max-width: 100%;
      }

      .Thumbnail {
        max-width: 100%;
        height: 260px;
        width: 100%;
      }

      img {
        max-width: 100%;
        height: 100%;
      }
    }

  }
}