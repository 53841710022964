@import './_fonts.scss';
@import './_themes.scss';

body {
  font-family: var(--font-family);
  font-size: var(--font-body-regular-size);
  line-height: var(--font-line-height-body-small);
  font-weight: var(--font-weight-regular);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-font-primary);
}

p {
  margin-top: 0.5rem;
  margin-bottom: 0;
}

p.Large {
  font-size: var(--font-body-large-size);
  line-height: var(--font-line-height-body);
}

p.Small {
  font-size: var(--font-body-small-size);
  line-height: var(--font-line-height-body);
}


p.Semibold {
  font-weight: var(--font-weight-semibold);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--font-line-height-header);
  font-weight: var(--font-weight-bold);
  margin: 0;
  padding: 0;
}

h1 {
  font-size: var(--font-h1-size);
  font-weight: var(--font-weight-black);
}

h2 {
  font-size: var(--font-h2-size);
}

h3 {
  font-size: var(--font-h3-size);
  font-weight: var(--font-weight-black);
  padding-bottom: 0.75rem;
}

h3.LargeSubtitle {
  font-weight: var(--font-weight-regular);
  color: var(--color-font-seconary);
}

h4 {
  font-size: var(--font-h4-size);
  font-weight: var(--font-weight-black);
  padding-bottom: 0.75rem;

}

h5 {
  font-size: var(--font-h5-size);
  padding-bottom: 0.7rem;

}

h6 {
  font-size: var(--font-h6-size);
}

.Label {
  font-size: var(--font-label-size);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: var(--color-font-label);

}

.Caption {
  font-size: var(--font-h4-size);
  font-weight: var(--font-weight-light);
  color: var(--color-font-secondary);
  line-height: var(--font-line-height-header);

}

h1.Gradient {
  background: linear-gradient(91.72deg, #{$color-purple-30} 0.86%, #{$color-cornflower} 22.31%, #{$color-aqua} 34.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

h4.Gradient,
h3.Gradient {
  background: linear-gradient(91.72deg, #{$color-purple-30} 0.86%, #{$color-cornflower} 42.31%, #{$color-aqua} 64.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}