.RowGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

@media only screen and (max-width: 769px) {
  .RowGrid {
    display: grid;
    grid-template-columns: 1fr;
  }
}