@import '../../../styles/styles.scss';

.Hero {
  background-color: var(--color-background-card);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 500px;
  grid-column: 1/4;
  overflow: hidden;


  .HeroImg {
    box-shadow: 1px 2px 25px rgba(136, 138, 157, 0.25);
    border-radius: 16px 16px 0px 0px;
    max-width: 960px;
    max-height: 470px;

    img {
      border-radius: 16px 16px 0px 0px;
      max-width: 100%;
      object-fit: cover;
      max-height: auto;
    }

  }
}

@media only screen and (max-width: 1000px) {
  .Hero {
    height: 360px;
    display: grid;
    grid-template-columns: minmax(32px, 1fr) minmax(280px, 960px) minmax(32px, 1fr);

    .HeroImg {
      grid-column: 2/3;
      max-height: 340px;

    }
  }
}

@media only screen and (max-width: 700px) {
  .Hero {
    height: 280px;
    display: grid;
    grid-template-columns: minmax(32px, 1fr) minmax(280px, 960px) minmax(32px, 1fr);

    .HeroImg {
      grid-column: 2/3;
      max-height: 260px;

    }
  }
}