@import '../../styles/_themes.scss';


.Maindetails {
  max-width: 960px;
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 2rem;
}

.Section__img {
  margin-top: 2rem;
  max-width: 960px;

  img {
    object-fit: contain;
    max-width: 100%;
  }

  .Alt {
    margin-bottom: 2rem;
    text-align: center;
    color: var(--color-font-secondary);
  }
}

.Section__content {
  max-width: 700px;
}

.Styled {
  filter: drop-shadow(1px 1px 10px rgba(107, 107, 107, 0.25));
  border-radius: 10px;
}