@import '../../styles/_themes.scss';


.Grid {}

.App {
  display: grid;
  grid-template-columns: minmax(32px, 1fr) minmax(280px, 960px) minmax(32px, 1fr);
  margin: 0;
  padding: 0;
  background-color: var(--color-background-page);
  min-height: 100vh;
}