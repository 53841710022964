@import '../../styles/_themes.scss';


.Links {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 1.25rem;
}

section.Intro {
  margin-bottom: 7rem;
  max-width: 840px;
}

.Announcement {
  background-color: var(--color-background-announcement);
  padding: 0.75rem 1rem 0.75rem 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.85rem;

  p {
    margin-top: 0;

    a {
      font-family: 'IBM Plex Mono', monospace;
    }
  }
}